<template>
<div>
    <SideBar :active="'orders'"/>
    <div class="order-page">

        <div class="loader" v-if="!order">
            <div class="orders-top-pane flex align-center justify-btw">

                <div class="pseudo-loader"></div>
                <div class="pseudo-loader"></div>

            </div>
            <div class="order-page-wrapper">
                <div class="order-page-top-pane flex align-center">
                    <div class="pseudo-loader"></div>
                    <div class="pseudo-loader ml-auto small round"></div>
                    <div class="pseudo-loader ml-3 small round"></div>
                </div>
            </div>
        </div>

        <div class="orders-top-pane flex align-center justify-btw" v-if="order">
            <h2> Order - {{ order.orderID }} </h2>

            <button class="btn fancy-btn fixed-btn flex center" v-if="status === 'active'" @click="openFixedOverlay">
                <div class="admin-icons mf-icon"></div>
                <span>Mark Fixed</span>
            </button>

            <button class="btn fancy-btn delivered-btn flex center" v-if="status === 'fixed'" @click="openDeliveredOverlay">
                <div class="admin-icons md-icon"></div>
                <span>Mark Delivered</span>
            </button>
        </div>

        <div class="order-page-wrapper" v-if="order">

            <div class="order-page-top-pane flex align-center">

                <span :class="classObj" class="status">
                    {{ status }}
                </span>

                <router-link
                    v-if="!order.delivered"
                    class="admin-icons edit-btn"
                    :to="{ name: 'orderEdit', params: { id: order.orderID } }"> </router-link>
                    <div class="admin-icons delete-btn" @click="openDeleteOverlay" :class="{ 'ml-auto': order.delivered }" ></div>

            </div>

            <h3 class="sub-headings">Order Details</h3>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span class="order-id"> Order ID: </span>
                    <p class="order-id"> {{ order.orderID }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Sim given: </span>
                    <p> {{ order.withSim ? "yes" : "No" }} </p>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Brand: </span> <p> {{ order.brand }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Model: </span> <p> {{ order.model }} </p>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Issue: </span> <p> {{ order.issue }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Estimated Price: </span> <p> Rs. {{ order.priceEstimate }} </p>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center mb-5">
                <div class="flex order-page-entry">
                    <span> Expected Delivery: </span> <p> {{ makeDate(order.expectedDeliveryDate) }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Expected Delivery Time: </span> <p> {{ order.expectedDeliveryTime }} </p>
                </div>
            </div>

            <h3 class="sub-headings">Customer Details</h3>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Customer Name: </span> <p> {{ order.customerName }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Customer Email: </span> <p> {{ order.customerEmail ? order.customerEmail : "Not available" }} </p>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center mb-5">
                <div class="flex order-page-entry">
                    <span> Customer Mobile: </span> <p> {{ order.mobileNumber ? order.mobileNumber : "Not available"}} </p>
                </div>
            </div>

            <h3 class="sub-headings">Other Details</h3>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Order Date: </span> <p> {{ makeDate(order.orderDate) }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Order Time: </span> <p> {{ makeTime(order.orderDate) }} </p>
                </div>
            </div>

            <div class="order-page-row flex justify-btw align-center">
                <div class="flex order-page-entry">
                    <span> Fixed: </span> <p> {{ order.fixed ? "Yes" : "No" }} </p>
                </div>
                <div class="flex order-page-entry">
                    <span> Delivered: </span> <p> {{ order.delivered ? "Yes" : "No" }} </p>
                </div>
            </div>

             <div
                class="order-page-row flex justify-btw align-center"
                :class="{'mb-5': status === 'delivered'}">
                <div class="flex order-page-entry">
                    <span> Order Taken By: </span> <p> {{ order.takenBy }} </p>
                </div>
                <div class="flex order-page-entry" v-if="order.fixedBy">
                    <span> Order Fixed By: </span> <p> {{ order.fixedBy }} </p>
                </div>
            </div>

            <div v-if="status === 'delivered'">
                <h3 class="sub-headings">Delivery Details</h3>
                <div class="order-page-row flex justify-btw align-center">
                    <div class="flex order-page-entry">
                        <span> Order Delivered By: </span> <p> {{ order.deliveredBy }} </p>
                    </div>
                    <div class="flex order-page-entry">
                        <span> Order Amount: </span> <p> Rs. {{ order.orderAmount }} </p>
                    </div>
                </div>
                <div class="order-page-row flex justify-btw align-center">
                    <div class="flex order-page-entry">
                        <span> Deliverd Date: </span> <p> {{ makeDate(order.deliveredOn) }} </p>
                    </div>
                    <div class="flex order-page-entry">
                        <span> Delivered Time: </span> <p> {{ makeTime(order.deliveredOn) }} </p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="overlay d-none" @click="closeOverlay">
        <div class="modal mark-fixed-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to mark this order as fixed?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn fixed-confirm-btn" @click="markFixed">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
        <div class="modal mark-delivered-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to mark this order as delivered?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn" @click="openConfirmedDeliveredOverlay">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
        <div class="modal confirm-delivered-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>

            <div class="form-element">
                <input
                    type="number" data-name="amount" v-model="orderAmount"
                    id="order-amount" class="form-input" placeholder="Enter order amount.">
            </div>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn mdc-btn" @click="markDelivered">CONFIRM</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">CANCEL</button>
            </div>
        </div>
        <div class="modal delete-order-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to delete this order?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn delete-confirm-btn" @click="deleteThisOrder">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SideBar from '@/components/admin/SideBar.vue';
import { mapGetters } from 'vuex';
import { Orders } from '@/services/Service';
import $ from 'jquery';

export default {
    data() {
        return {
            order: null,
            orderAmount: null
        }
    },
    components: {
        SideBar,
    },
    computed: {
        ...mapGetters(['getOrderByID']),
        status() {
            return !this.order.fixed ? "active" : this.order.delivered ? "delivered" : "fixed";
        },
        classObj() {
            return {
                "active": this.status === "active",
                "fixed": this.status === "fixed",
                "delivered": this.status === "delivered"
            }
        },
        makeDate() {
            return date => {
                date = typeof date === "string" ? new Date(date) : date;
                return date.toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' });
            }
        },
        makeTime() {
            return date => {
                date =  typeof date === "string" ? new Date(date) : date;
                return date.toLocaleString('en-IN', { timeStyle: "short" }).toUpperCase();
            }
        }
    },
    methods: {
        async fetchOrder(id) {
            const resp = await Orders.getByID({ orderID: id });
            this.order = resp.data.result;
        },
        openFixedOverlay() {
            $('.overlay').removeClass('d-none');
            $('.mark-fixed-modal').removeClass('d-none');
        },
        openDeliveredOverlay() {
            $('.overlay').removeClass('d-none');
            $('.mark-delivered-modal').removeClass('d-none');
        },
        openConfirmedDeliveredOverlay() {
            $('.mark-delivered-modal').addClass('d-none');
            $('.overlay').removeClass('d-none');
            $('.confirm-delivered-modal').removeClass('d-none');
        },
        openDeleteOverlay() {
            $('.overlay').removeClass('d-none');
            $('.delete-order-modal').removeClass('d-none');
        },
        handleModalClick(e) {
            e.stopPropagation();
        },
        closeOverlay() {
            $('.overlay').addClass('d-none');
            $('.modal').addClass('d-none');
        },
        async deleteThisOrder() {
            try {
                $('.delete-confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
                $('.delete-confirm-btn').blur();
                const response = await Orders.delete({ orderID: this.order.orderID });
                if( response.status === 200 )
                {
                    const notification = {
                        type: 'success',
                        message: 'Order deleted Successfully.'
                    }
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                    this.$router.push({ name: 'orders' });
                }
            } catch (error)
            {
                $('.delete-confirm-btn').html('Yes');
                const { message } = error.response.data;
                const notification = { type: 'error', message };
                this.$store.dispatch('addNotification', notification);
            }
        },
        async markFixed() {
            try {
                $('.fixed-confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
                $('.fixed-confirm-btn').blur();
                const response = await Orders.ready({ orderID: this.order.orderID });
                if( response.status === 200 )
                {
                    const notification = {
                        type: 'success',
                        message: 'Order marked as fixed.'
                    }
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                    this.order.fixed = true;
                    this.order.fixexBy = this.$store.state.user.username;
                }
                $('.fixed-confirm-btn').html('Yes');
            } catch (error)
            {
                $('.fixed-confirm-btn').html('Yes');
                const { message } = error.response.data;
                const notification = { type: 'error', message };
                this.$store.dispatch('addNotification', notification);
            }
        },
        async markDelivered() {
            if( !this.orderAmount )
                return this.addErrorMessage('order-amount');
            try {
                $('.mdc-btn').html(`<div class="spinner-border" role="status"></div>`);
                $('.mdc-btn').blur();
                const response = await Orders.deliver({
                    orderID: this.order.orderID,
                    orderAmount: Number(this.orderAmount)
                });
                if( response.status === 200 )
                {
                    const notification = {
                        type: 'success',
                        message: 'Order marked as delivered.'
                    }
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                    this.order.delivered = true;
                    this.order.orderAmount = this.orderAmount;
                    this.order.deliveredBy = this.$store.state.user.username;
                    this.order.deliveredOn = new Date();
                }
                $('.mdc-btn').html('Confirm');
            } catch (error)
            {
                $('.mdc-btn').html('Confirm');
                const { message } = error.response.data;
                const notification = { type: 'error', message };
                this.$store.dispatch('addNotification', notification);
            }
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        }
    },
    created()
    {
        const id = this.$route.params.id;
        this.order = this.getOrderByID( id );
        if( this.order === undefined ) {
            this.fetchOrder(id);
        }
    },
    watch: {
        orderAmount(newValue) {
            if( newValue === '' )
                this.addErrorMessage('order-amount');
            else
                this.removeErrorMessage('order-amount');
        }
    },
}
</script>

<style scoped>
.order-page
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
}

.orders-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.1);
    background-color: #fff;
}

h2
{
    font-weight: 800;
}

.status
{
    padding: 10px 20px;
    display: inline-block;
    border-radius: 15px;
    color: whitesmoke;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1;
}

.status.active { background-color: #51c2d5; }

.status.fixed { background-color: #00917c; }

.status.delivered { background-color: #ff9a76; }

.order-page-wrapper
{
    margin: 40px 25px;
    height: calc(100vh - 160px);
    border: 2px solid #f1f1f1;
    box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: auto;
    border-right: 0;
    padding-bottom: 25px;
}

.order-page-top-pane
{
    height: 65px;
    padding: 0 25px;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 30px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0; left: 0;
}

.edit-btn
{
    background-size: 359.2px 303.2px;
    width: 40px; height: 40px;
    background-position: -8px -199.2px;
    cursor: pointer;
    margin-left: auto;
}

.edit-btn:hover
{
    background-size: 359.2px 303.2px;
    width: 40px; height: 40px;
    background-position: -64px -199.2px;
}

.delete-btn
{
    background-size: 359.2px 303.2px;
    width: 40px; height: 40px;
    background-position: -120px -143.2px;
    cursor: pointer;
    margin-left: 12px;
}

.delete-btn:hover
{
    background-size: 359.2px 303.2px;
    width: 40px; height: 40px;
    background-position: -206.4px -8px;
}

.fixed-btn, .delivered-btn
{
    height: 42px; width: 200px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 1;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.fixed-btn { background-color: #00917c; }

.delivered-btn { background-color: #ff9a76; }

.order-page-row
{
    min-height: 50px;
    padding: 0 25px;
}

.order-page-entry
{
    width: 50%;
}

.order-page-entry span
{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: grey;
}

.order-page-entry p
{
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.order-page-entry p.order-id, .order-page-entry span.order-id
{
    font-family: 'Poppins', sans-serif;
}

.mb-5
{
    margin-bottom: 40px;
    position: relative;
}

.mb-5::after
{
    content: '';
    position: absolute;
    height: 1px; width: 100%;
    bottom: -15px; left: 0;
    border-bottom: 2px solid #f1f1f1;
}

.sub-headings
{
    font-size: 20px;
    margin: 0 25px 24px;
}

.overlay
{
    background-color: rgb(0,0,0,0.5);
    height: 100vh; width: 100vw;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
}

.modal
{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 350px;
    border-radius: 12px;
    padding: 60px 25px 30px;
}

.modal span
{
    position: absolute;
    right: 10px; top: 10px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.modal span::before, .modal span::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: black;
}

.modal span::before { transform: translateX(-50%) rotate(45deg); }

.modal span::after { transform: translateX(-50%) rotate(-45deg); }

.modal span:hover { opacity: 0.5; }

.modal p
{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.btns-box { margin-top: 25px; }

.confirm-btn, .cancel-btn
{
    height: 40px; width: 100px;
    border-radius: 3px;
    font-size: 16px;
    padding-top: 3px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.confirm-btn { background-color: #00917c; margin-left: auto; margin-right: 15px; }

.cancel-btn { background-color: #E20613; margin-right: auto; }

.form-input
{
    height: 45px; width: 100%;
    border: 2px solid #000;
    background-color: transparent;
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 6px;
}

.form-input:focus { border-color: #E20613; }

.mf-icon
{
    background-size: 224.5px 189.5px;
    width: 25px; height: 25px;
    background-position: -164px -40px;
    margin-right: 10px;
}

.md-icon
{
    background-size: 269.4px 227.4px;
    width: 30px; height: 30px;
    background-position: -154.8px -90px;
    margin-right: 6px;
}

.fixed-btn span, .delivered-btn span { padding-top: 2px; }

</style>
